import store from '@/store'

export default {
    state: {
        uyeler: [],
        ihaleDetay: [],
        ihaleListesi: [],
        acikIhaleler: [],
        kapaliIhaleler: [],
        firmaListesi: [],
    },
    getters: {
        FIRMA_LISTESI_GET(state) {
            if (state.firmaListesi) {
                return state.firmaListesi
            }
            return false
        },
        KAPALI_IHALE_GET(state) {
            if (state.kapaliIhaleler) {
                return state.kapaliIhaleler
            }
            return false
        },
        ACIK_IHALE_GET(state) {
            if (state.acikIhaleler) {
                return state.acikIhaleler
            }
            return false
        },
        IHALE_DETAY_GET(state) {
            if (state.ihaleDetay) {
                return state.ihaleDetay
            }
            return false
        },
        IHALE_LISTESI_GET(state) {
            if (state.ihaleListesi) {
                return state.ihaleListesi
            }
            return false
        },
        KOMISYON_UYELER_GET(state) {
            if (state.uyeler) {
                return state.uyeler
            }
            return false
        },
    },
    mutations: {
        FIRMA_LISTESI_ACTION(state, payload) {
            payload !== 'remove' ? state.firmaListesi = { ...payload } : state.firmaListesi = []
        },
        ACIK_IHALE_ACTION(state, payload) {
            payload !== 'remove' ? state.acikIhaleler = { ...payload } : state.acikIhaleler = []
        },
        KAPALI_IHALE_ACTION(state, payload) {
            payload !== 'remove' ? state.kapaliIhaleler = { ...payload } : state.kapaliIhaleler = []
        },
        IHALE_LIST_ACTION(state, payload) {
            payload !== 'remove' ? state.ihaleListesi = { ...payload } : state.ihaleListesi = []
        },
        IHALE_DETAY_ACTION(state, payload) {
            payload !== 'remove' ? state.ihaleDetay = { ...payload } : state.ihaleDetay = []
        },
        KOMISYON_UYELER_ACTION(state, payload) {
            payload !== 'remove' ? state.uyeler = { ...payload } : state.uyeler = []
        },
    },
    actions: {
        teklifDuzenle({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'ihale',
                methodName: 'ihaleTeklifDuzenle',
                ihaleID: authData.ihaleID,
                firmaID: authData.firmaID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        teklifKabulEt({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'ihale',
                methodName: 'ihaleTeklifOnayla',
                ihaleID: authData.ihaleID,
                firmaID: authData.firmaID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        teklifSil({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'ihale',
                methodName: 'ihaleTeklifSil',
                atamaID: authData,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        teklifIptal({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'ihale',
                methodName: 'ihaleTeklifIptal',
                ihaleID: authData,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        firmaSil({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'ihale',
                methodName: 'ihaleFirmaSil',
                firmaID: authData,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        firmaListesi({ commit }) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'ihale',
                methodName: 'firmaListesi',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        const data = { ...res }
                        delete data.userToken
                        commit('FIRMA_LISTESI_ACTION', data)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        firmaKayit({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'ihale',
                methodName: 'ihaleFirmaKaydet',
                firmaAdi: authData.adi,
                firmaUnvan: authData.unvan,
                firmaAdres: authData.adres,
                firmaTelefon: authData.telefon,
                firmaVergiNo: authData.vergiNo,
                firmaVergiDairesi: authData.vergiDairesi,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        acikIhale({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'ihale',
                methodName: 'ihaleKayitlari',
                filtre: 'true',
                status: 0,
                searchKey: authData.searchKey,
                baslangic: authData.baslangic || 0,
                uzunluk: authData.uzunluk || 20,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('ACIK_IHALE_ACTION', res.liste)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        kapaliIhale({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'ihale',
                methodName: 'ihaleKayitlari',
                filtre: 'true',
                status: 1,
                searchKey: authData.searchKey,
                baslangic: authData.baslangic || 0,
                uzunluk: authData.uzunluk || 20,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('KAPALI_IHALE_ACTION', res.liste)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        ihaleListesi({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'ihale',
                methodName: 'ihaleKayitlari',
                searchKey: authData.searchKey,
                baslangic: authData.baslangic || 0,
                uzunluk: authData.uzunluk || 20,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('IHALE_LIST_ACTION', res.liste)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        ihaleSil({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'ihale',
                methodName: 'ihaleSil',
                ihaleID: authData,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        komisyonSema({ commit }) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'ihale',
                methodName: 'komisyonSema',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        ihaleDetay({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'ihale',
                methodName: 'ihaleDetay',
                ihaleID: authData,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        const data = { ...res }
                        delete data.userToken
                        commit('IHALE_DETAY_ACTION', data)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        komisyonUyeDuzenle({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'ihale',
                methodName: 'komisyonUyeDuzenle',
                uyeID: authData.uyeID,
                adSoyad: authData.adSoyad,
                unvan: authData.unvan,
                gorev: authData.gorev,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        komisyonUyeSil({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'ihale',
                methodName: 'komisyonUyeSil',
                uyeID: authData,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        komisyonUyeKaydet({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'ihale',
                methodName: 'komisyonUyeKaydet',
                adSoyad: authData.adSoyad,
                unvan: authData.unvan,
                gorev: authData.gorev,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        komisyonUyeleri({ commit }) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'ihale',
                methodName: 'komisyonUyeListesi',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('KOMISYON_UYELER_ACTION', res.liste)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
    },
}

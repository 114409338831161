import Vue from 'vue'
import Vuex from 'vuex'
import jwt_decode from 'jwt-decode'
import router from '@/router'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
// eslint-disable-next-line import/no-cycle
import cari from './cari'
import stok from './stok'
import fatura from './fatura'
import gider from './gider'
import depo from './depo'
import servis from './servis'
import kasa from './kasa'
import banka from './banka'
import calendar from './calendar'
import rapor from './rapor'
import ihale from './ihale'
import salon from './salon'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        POST_URL: process.env.NODE_ENV === 'production' ? 'https://finans.isletmem.com.tr/api/webservis.php' : 'https://finans.isletmem.com.tr/api/webservis.php',
        isAuth: false,
        level: 0,
        userData: [],
        userToken: '',
        colors: [],
        dashFinans: [],
        dashInfo: [],
        aylikGrafik: [],
        firmaBilgileri: [],
    },
    getters: {
        FIRMA_BILGILERI(state) {
            return state.firmaBilgileri
        },
        DASH_AYLIK_GRAFIK(state) {
            if (state.aylikGrafik) {
                return {
                    alisFaturalari: state.aylikGrafik.alisFaturalari || '',
                    bekleyenFaturaOdemeleri: state.aylikGrafik.bekleyenFaturaOdemeleri || '',
                    bekleyenFaturaTahsilatlari: state.aylikGrafik.bekleyenFaturaTahsilatlari || '',
                    faturaOdemeleri: state.aylikGrafik.faturaOdemeleri || '',
                    faturaTahsilatlari: state.aylikGrafik.faturaTahsilatlari || '',
                    kasaBakiye: state.aylikGrafik.kasaBakiye || '',
                    kasaCikisi: state.aylikGrafik.kasaCikisi || '',
                    kasaGirisi: state.aylikGrafik.kasaGirisi || '',
                    odemeler: state.aylikGrafik.odemeler || '',
                    satisFaturalari: state.aylikGrafik.satisFaturalari || '',
                    tahsilatlar: state.aylikGrafik.tahsilatlar || '',
                }
            }
            return false
        },
        DASH_INFO(state) {
            if (state.dashInfo) {
                return {
                    alacakliKartlar: state.dashInfo.alacakliKartlar || '',
                    borcluKartlar: state.dashInfo.borcluKartlar || '',
                    acikAlisFaturalari: state.dashInfo.acikAlisFaturalari || '',
                    acikSatisFaturalari: state.dashInfo.acikSatisFaturalari || '',
                }
            }
            return false
        },
        DASH_FINANS(state) {
            if (state.colors) {
                return {
                    satisFaturalari: state.dashFinans.satisFaturalari || '',
                    alisFaturalari: state.dashFinans.alisFaturalari || '',
                    tahsilatlar: state.dashFinans.tahsilatlar || '',
                    odemeler: state.dashFinans.odemeler || '',
                    bekleyenFaturaTahsilatlari: state.dashFinans.bekleyenFaturaTahsilatlari || '',
                    bekleyenFaturaOdemeleri: state.dashFinans.bekleyenFaturaOdemeleri || '',
                }
            }
            return false
        },
        COLORS_GET(state) {
            if (state.colors) {
                return {
                    shadowColor: state.colors.shadowColor || state.colors.bgSuccess,
                    menuBarBg: state.colors.menuBarBg || '',
                    bgDanger: state.colors.bgDanger || '',
                    bgInfo: state.colors.bgInfo || '',
                    bgPrimary: state.colors.bgPrimary || '',
                    bgSuccess: state.colors.bgSuccess || '',
                    bgWarning: state.colors.bgWarning || '',
                    cardTitle: state.colors.cardTitle || '',
                    cardTitleButton: state.colors['cardTitle-Button'],
                    cardTitleL: state.colors['cardTitle-L'] || '',
                    tableTitle: state.colors.tableTitle || '',
                    footerBg: state.colors.footerBg || '',
                    headerBg: state.colors.headerBg || '',
                }
            }
            return false
        },
        TOKEN_GET(state) {
            return state.userToken || localStorage.getItem('buroUserToken')
        },
        getRole(state) {
            return state.level
        },
        isAuthenticated(state) {
            return state.isAuth
        },
        TURKISH_MONTH: () => val => {
            switch (val) {
                case '01':
                    return 'Ocak'
                    break
                case '02':
                    return 'Şubat'
                    break
                case '03':
                    return 'Mart'
                case '04':
                    return 'Nisan'
                case '05':
                    return 'Mayıs'
                    break
                case '06':
                    return 'Haziran'
                    break
                case '07':
                    return 'Temmuz'
                    break
                case '08':
                    return 'Ağustos'
                    break
                case '09':
                    return 'Eylül'
                    break
                case '10':
                    return 'Ekim'
                    break
                case '11':
                    return 'Kasım'
                    break
                case '12':
                    return 'Aralık'
                    break
                default:
                    return 'Ocak'
            }
        },
        notificationSettings: () => val => {
            if (val?.result) {
                const cevap = JSON.stringify(val.result.errMsg)
                const reply = cevap.slice(1, -1)
                return reply
            }
            return false
        },
    },
    mutations: {
        FIRMA_BILGILERI_ACTION(state, { payload }) {
            state.firmaBilgileri = payload
        },
        DASH_AYLIK_GRAFIK(state, { payload }) {
            state.aylikGrafik = payload
        },
        DASH_INFO_ACTION(state, { payload }) {
            state.dashInfo = payload
        },
        DASH_FINANS_ACTION(state, { payload }) {
            state.dashFinans = payload
        },
        COLORS_ACTION(state, { payload }) {
            const action = { ...payload }
            state.colors = action
        },
        removeUserData(state) {
            state.level = 0
            state.userData = []
        },
        setUserData(state, jwt) {
            state.level = jwt.level
            state.userData = jwt
            localStorage.setItem('buroFirmaKodu', jwt.firmaKodu)
            localStorage.setItem('buroFullName', jwt.FullName)
            localStorage.setItem('buroUserName', jwt.userName)
        },
        setToken(state, token) {
            if (token) {
                state.userToken = token
                localStorage.setItem('buroUserToken', token)
            }
        },
        removeToken(state) {
            state.userToken = ''
            localStorage.removeItem('buroUserToken')
        },
    },
    actions: {
        async authControl({
                              commit,
                              state,
                          }) {
            const today = new Date()
            const dd = String(today.getDate())
                .padStart(2, '0')
            const mm = String(today.getMonth() + 1)
                .padStart(2, '0')
            const yyyy = today.getFullYear()
            let todayy
            todayy = yyyy + '-' + mm + '-' + dd
            localStorage.setItem('buroCurrentDate', todayy)
            let token = localStorage.getItem('buroUserToken')
            const data = {
                crm_token: token,
                serviceName: 'dashBoard',
            }
            if (token) {
                $.ajax({
                    type: 'POST',
                    url: state.POST_URL,
                    data,
                    success(res) {
                        if (res.status == 200 && jwt_decode(res.userToken).defaultModul === 'buro') {
                            state.isAuth = true
                            commit({
                                type: 'FIRMA_BILGILERI_ACTION',
                                payload: res.firmaBilgileri,
                            })
                            commit({
                                type: 'COLORS_ACTION',
                                payload: res.colors,
                            })
                            commit({
                                type: 'DASH_AYLIK_GRAFIK',
                                payload: res.kasaGrafik,
                            })
                            commit({
                                type: 'DASH_FINANS_ACTION',
                                payload: res.kasaOzeti,
                            })
                            commit({
                                type: 'DASH_INFO_ACTION',
                                payload: res.dashBoardInfo,
                            })
                            // router.push({ path: router.history._startLocation })
                            commit('setToken', res.userToken)
                            commit('setUserData', jwt_decode(res.userToken))
                        } else {
                            state.isAuth = false
                            if (res.responseJSON.tokenStatus !== 503) {
                                commit('removeToken')
                                router.push('/login')
                            } else {
                                router.push('/expariedLogin')
                            }
                        }
                    },
                    error(err) {
                        state.isAuth = false
                        if (err.responseJSON.tokenStatus !== 503) {
                            commit('removeToken')
                            router.push('/login')
                        } else {
                            router.push('/expariedLogin')
                        }
                    },
                    dataType: 'json',
                })
            } else {
                router.push('/login')
            }
        },
        logIn({
                  commit,
                  state,
              }, authData) {
            const data = {
                serviceName: 'account',
                methodName: 'logIn',
                user: authData.user,
                pwd: authData.pwd,
                firmaKodu: authData.firmaKodu,
                modul: 'buro',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: state.POST_URL,
                    data,
                    success(res) {
                        if (res.status == 200) {
                            if (jwt_decode(res.userToken).defaultModul === 'buro') {
                                resolve(res)
                                state.isAuth = true
                                commit({
                                    type: 'FIRMA_BILGILERI_ACTION',
                                    payload: res.firmaBilgileri,
                                })
                                commit({
                                    type: 'COLORS_ACTION',
                                    payload: res.colors,
                                })
                                commit({
                                    type: 'DASH_AYLIK_GRAFIK',
                                    payload: res.kasaGrafik,
                                })
                                commit({
                                    type: 'DASH_FINANS_ACTION',
                                    payload: res.kasaOzeti,
                                })
                                commit({
                                    type: 'DASH_INFO_ACTION',
                                    payload: res.dashBoardInfo,
                                })
                                commit('setToken', res.userToken)
                                commit('setUserData', jwt_decode(res.userToken))
                                const today = new Date()
                                const dd = String(today.getDate())
                                    .padStart(2, '0')
                                const mm = String(today.getMonth() + 1)
                                    .padStart(2, '0')
                                const yyyy = today.getFullYear()
                                let todayy
                                todayy = yyyy + '-' + mm + '-' + dd
                                localStorage.setItem('buroCurrentDate', todayy)
                            } else {
                                reject('moduleError')
                            }
                        } else {
                            reject(res)
                        }
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
    },
    modules: {
        salon,
        ihale,
        rapor,
        calendar,
        banka,
        kasa,
        servis,
        depo,
        gider,
        fatura,
        stok,
        cari,
        app,
        appConfig,
        verticalMenu,
    },
    strict: process.env.DEV,
})

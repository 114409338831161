import Vue from 'vue'

// ?? < font awesome icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import 'font-awesome-animation/css/font-awesome-animation.min.css'
import {
    faUserSecret,
    faPhone,
    faTreeCity,
    faBuilding,
    faMapLocation,
    faTurkishLiraSign,
    faCheckDouble,
    faUser,
    faMessage,
    faFileExport,
    faMinus,
    faPlus,
    faUserPen,
    faTrashCan,
    faTurkishLira,
    faClockRotateLeft,
    faReceipt,
    faBoxArchive,
    faCalendarDays,
    faHandPointer,
    faCircleDot,
    faHeart,
    faFloppyDisk,
    faHashtag,
    faBars,
    faPenToSquare,
    faCheck,
    faXmark,
    faPen,
    faFileContract,
    faEye,
    faIdCardClip,
    faPaperPlane,
    faAngleLeft,
    faFileExcel,
    faPrint,
    faHouse,
    faChevronRight,
    faRightLeft,
    faChevronDown,
    faRotateLeft,
    faMoneyBill,
    faCreditCard,
    faUsers,
    faMagnifyingGlass,
    faComment,
    faWarehouse,
    faBoxesStacked,
    faCubesStacked,
    faX,
    faLayerGroup,
    faArrowRotateLeft,
    faBox,
    faCircleUser,
    faLock,
    faEnvelope,
    faCity,
    faIndustry,
    faN,
    faAlignLeft,
    faBuildingColumns,
    faShekelSign,
    faArrowRightToBracket,
    faFileInvoice,
    faBarcode,
    faGear,
    faGavel,
    faFeather,
    faCircleInfo,
    faHourglassHalf,
    faList,
    faFileWord,
    faFilePdf,
    faArrowDown,
    faLocationDot,
    faLandmark,
    faInfo,
    faCheckToSlot,
    faRectangleList,
    faSolarPanel,
    faHandshake,
    faRing,
    faToriiGate,
    faUserTie,
    faTruckRampBox,
    faClipboardList,
    faStar,
    faChevronLeft,
} from '@fortawesome/free-solid-svg-icons'
import { faFileLines, faAddressCard } from '@fortawesome/free-regular-svg-icons'

library.add(faUserSecret, faPhone, faTreeCity, faBuilding, faMapLocation, faTurkishLiraSign, faCheckDouble, faUser, faMessage, faFileExport, faMinus, faPlus, faUserPen, faTrashCan, faTurkishLira, faClockRotateLeft, faFileLines, faReceipt, faBoxArchive, faCalendarDays, faHandPointer, faCircleDot, faHeart, faFloppyDisk, faHashtag, faBars, faPenToSquare, faCheck, faXmark, faPen, faFileContract, faEye, faIdCardClip, faAddressCard, faPaperPlane, faAngleLeft, faFileExcel, faPrint, faHouse, faChevronRight, faRightLeft, faChevronDown, faRotateLeft, faMoneyBill, faCreditCard, faUsers, faMagnifyingGlass, faComment, faWarehouse, faBoxesStacked, faCubesStacked, faX, faLayerGroup, faArrowRotateLeft, faBox, faCircleUser, faLock, faEnvelope, faCity, faIndustry, faN, faAlignLeft, faBuildingColumns, faShekelSign, faArrowRightToBracket, faFileInvoice, faBarcode, faGear, faGavel, faFeather, faCircleInfo, faHourglassHalf, faList, faFileWord, faFilePdf, faArrowDown, faLocationDot, faLandmark, faInfo, faCheckToSlot, faRectangleList, faSolarPanel, faHandshake, faRing, faToriiGate, faUserTie, faTruckRampBox, faClipboardList, faStar, faChevronLeft)
Vue.component('font-awesome-icon', FontAwesomeIcon)
// ?? >
